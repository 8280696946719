<template>
  <div class="sticky-top">
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <router-link class="navbar-brand" to="/"
          ><img src="../assets/img/logo new.png" alt=""
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent1"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="isToggled = !isToggled"
          :class="{ active: isToggled }"
        >
          <font-awesome-icon
            :icon="['fa-solid', isToggled ? 'fa-xmark' : 'fa-align-right']"
          />
        </button>

        <div
          v-if="!isMenuRoute"
          class="collapse navbar-collapse"
          id="navbarSupportedContent1"
        >
          <ul class="navbar-nav navbar-hide ms-auto mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/"
                ><font-awesome-icon
                  icon="fa-solid fa-house"
                  class="me-1"
                />Home</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/mitra"
                ><font-awesome-icon
                  :icon="['fas', 'store']"
                  class="me-1"
                />Mitra Kami</router-link
              >
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/beranda"
                ><font-awesome-icon
                  :icon="['fas', 'boxes-stacked']"
                  class="me-1"
                />Product</router-link
              >
            </li> -->
          </ul>

          <!-- navbar mobile toggle -->
          <ul class="navbar-nav ms-auto mb-lg-0 navbar-show">
            <li class="nav-item">
              <router-link class="nav-link" to="/mitra"
                ><font-awesome-icon
                  :icon="['fas', 'store']"
                  class="me-1"
                />Mitra Kami</router-link
              >
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/Beranda"
                ><font-awesome-icon
                  :icon="['fas', 'boxes-stacked']"
                  class="me-1"
                /><span class="px-2">Product</span></router-link
              >
            </li> -->
          </ul>
          <!-- akhir navbar mobile toggle  -->
        </div>
      </div>
    </nav>
    <!-- akhir navbar -->
  </div>
</template>

<script>
// import Swal from "sweetalert2";
// import { EventBus } from "@/event-bus.js";
// import router from "@/router";
// import apis from "@/apis";
export default {
  name: "NavbarComponent",
  data() {
    return {
      isToggled: false,
    };
  },
  computed: {
    isMenuRoute() {
      return this.$route.name === "MitraDetail";
    },
  },
  methods: {
    toggleToggler() {
      this.isToggled = !this.isToggled;
    },
  },
};
</script>

