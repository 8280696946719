<template>
  <div class="notfound">
    <img src="../assets/img/Monster 404 Error-bro.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
<style>
.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.notfound img {
  width: 50% !important;
}
</style>
