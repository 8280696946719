<template>
  <div class="mitraSection">
    <navbar-home-component />
    <div class="container">
      <div class="contentMitra">
        <div class="headerMitra">
          <p class="titleHeader">Mitra Kami</p>
          <p class="descHeader">Tersebar diseluruh Indonesia</p>
        </div>
        <div
          class="row d-flex justify-content-center row-cols-1 row-cols-md-2 row-cols-lg-4 g-2"
        >
          <div v-for="item in mitra" :key="item.id" class="col">
            <div class="card card-mitra">
              <div>
                <p class="nameMitra">{{ item.name }}</p>
                <p class="addressMitra">{{ item.address }}</p>
              </div>
              <div class="footerCard">
                <a
                  :href="'http://wa.me/62' + item.phone"
                  class="btn numberMitra"
                  target="_blank"
                >
                  <span class="numberContent">
                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                  </span>
                </a>
                <router-link
                  :to="{
                    name: 'MitraDetail',
                    params: { nameStoreMitra: item.masking_name },
                  }"
                  class="btn btnMitra"
                >
                  <font-awesome-icon
                    :icon="['fas', 'arrow-up-right-from-square']"
                    class="me-1"
                  />
                  Kunjungi Website
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from "@/apis";
import NavbarHomeComponent from "@/components/NavbarHomeComponent.vue";
export default {
  components: { NavbarHomeComponent },
  data() {
    return {
      mitra: [],
    };
  },
  async mounted() {
    try {
      const { data } = await apis.apis.post(apis.PublicPath + "/merchant");
      this.mitra = data;
    } catch (error) {
      //   console.error("Error fetching data:", error);
    }
  },
};
</script>